<template>
    <div class="users">
        <nav class="breadcrumb" aria-label="breadcrumbs">
            <ul>
                <li><router-link to="/">Dashboard</router-link></li>
                <li class="is-active"><a href="#" aria-current="page">Users</a></li>
            </ul>
        </nav>
        <p class="total-results">{{totalResults}} Results</p>

        <div class="field is-grouped">
            <h1 class="title">Users</h1>
            <b-dropdown v-show="showAddUserDropdownTrigger" @active-change="dropdownActiveChange">
                <template #trigger>
                    <div class="button is-primary is-small is-rounded p-3 mt-1 ml-3" aria-haspopup="true" aria-controls="dropdown-menu1" :disabled="isLoading">
                        <span class="icon is-small"><font-awesome-icon icon="plus" /></span>
                    </div>
                </template>
            </b-dropdown>
            <div v-show="!showAddUserDropdownTrigger" class="button is-primary is-small is-rounded p-3 mt-1 ml-3" aria-haspopup="true" aria-controls="dropdown-menu1" :disabled="isLoading">
                <span class="icon is-small"><font-awesome-icon icon="plus" /></span>
            </div>
            <div class="block p-2">
                <div :class="usersDropdownActive ? 'dropdown is-active' : 'dropdown'">
                    <div class="dropdown-menu" id="dropdown-menu1" role="menu">
                        <div class="dropdown-content">
                            <div class="dropdown-item">
                                <router-link class="subtitle is-6" to="user">
                                    Add One User
                                </router-link>
                            </div>
                            <div class="dropdown-item" @click="importUsersModalActive = !importUsersModalActive">
                                <a class="subtitle is-6"> Add Multiple </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="field has-addons mb-0">
            <div class="control is-expanded has-icons-right mr-4">
                <input id="searchBox" ref="searchBox" class="input" type="text" placeholder="Search users..."
                       v-model="userSearch" @keyup.enter="newSearch" autocomplete="off">
                <span v-if="userSearch" class="icon is-small is-right">
                    <a class="delete" @click="clearSearchTerm"></a>
                </span>
            </div>
            <div class="control mr-4">
                <a class="button is-primary" id="search-button" @click="newSearch" :disabled="isLoading">
                    Search
                </a>
            </div>
            <FilterDropdown :displayAccount=true
                            :defaultAccountPublicId=accountPublicId
                            :displayUserRole=true
                            :defaultUserRole=userRole
                            :displayUserStatus=true
                            :defaultUserStatus=userStatus
                            :filtersApplied=filtersApplied
                            @accountSelected="accountSelected"
                            @userStatusSelected="userStatusSelected"
                            @userRoleSelected="userRoleSelected"
                            @clearFilters="clearFilters" />
        </div>

        <Loading v-if="isLoading" />
        <div v-else class="table-container">
            <table class="table is-striped is-fullwidth has-top-border has-bottom-border">
                <thead>
                    <tr>
                        <th><abbr title="Public Id">PId</abbr></th>
                        <th>Email</th>
                        <th>First Name</th>
                        <th>Last Name</th>
                        <th>Role</th>
                        <th>Status</th>
                        <th></th>
                    </tr>
                </thead>

                <tbody>
                    <tr v-for="(user, index) in users" :key="'user' + index">
                        <td>
                            <router-link :to="'/user/' + user.publicId">{{ user.publicId }}</router-link>
                        </td>
                        <td>
                            <router-link :to="'/user/' + user.publicId">{{ user.email }}</router-link>
                        </td>
                        <td> {{ user.firstName }}</td>
                        <td> {{ user.lastName }}</td>
                        <td> {{ roleTypeDictionary[user.role] }}</td>
                        <td> {{ statusTypeDictionary[user.status] }}</td>
                        <td>
                            <button class="button is-white is-inverted is-small" @click="veryifyDeleteUser(user)">
                                <font-awesome-icon :icon="['fa', 'trash-alt']" />
                            </button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>

        <Pagination :isLoading="isLoading" :currentPage="currentPage" :totalPages="totalPages" v-on:setPage="n => setPage(n)" />

        <UserImportModal v-show="importUsersModalActive"
                         @close="importUsersModalActive = false" />
    </div>
</template>

<script>
    import { secureGet, securePost, secureDelete } from '../secureFetch.js';

    import Pagination from '../components/Pagination';
    import Loading from '../components/Loading';
    import FilterDropdown from '../components/FilterDropdown'
    import UserImportModal from '../components/UserImportModal'
    import AccountDropdown from '../components/AccountDropdown';

    export default {
        name: 'Users',
        components: {
            Pagination,
            Loading,
            FilterDropdown,
            UserImportModal,
            AccountDropdown
        },
        computed: {
            hostUrl: function() {
                return this.$store.state.hostUrl;
            },
            userSearch: {
                set(value) {
                    this.$store.commit('updateUserSearchFilter', value);
                },
                get() {
                    return this.$store.state.userFilters.searchTerm;
                }
            },
            currentPage: {
                set(value) {
                    this.$store.commit('updateUserPageFilter', value);
                },
                get() {
                    return this.$store.state.userFilters.page;
                }
            },
            accountPublicId: {
                set(value) {
                    this.$store.commit('updateUserAccountIdFilter', value);
                },
                get() {
                    return this.$store.state.userFilters.accountPublicId;
                }
            },
            userRole: {
                set(value) {
                    this.$store.commit('updateUserRoleFilter', value);
                },
                get() {
                    return this.$store.state.userFilters.userRole;
                }
            },
            userStatus: {
                set(value) {
                    this.$store.commit('updateUserStatusFilter', value);
                },
                get() {
                    return this.$store.state.userFilters.userStatus;
                }
            },
            filtersApplied: function() {
                var filters = 0;

                if (this.$store.state.userFilters.accountPublicId != null) {
                    filters++;
                }

                if (this.$store.state.userFilters.userRole != null) {
                    filters++;
                }

                if (this.$store.state.userFilters.userStatus != null) {
                    filters++;
                }

                return filters;
            }
        },
        data() {
            return {
                isLoading: true,
                errorMessage: null,
                users: [],
                resultsPerPage: 25,
                totalPages: 0,
                totalResults: 0,
                roleTypeDictionary: { 0: "Unknown", 1: 'Standard', 2: 'Admin', 3: 'Integration', 4: 'Support' },
                statusTypeDictionary: { 0: "Unknown", 1: 'Active', 2: 'Deleted', 3: 'Pending', 4: 'Inactive' },
                usersDropdownActive: false,
                importUsersModalActive: false,
                showAddUserDropdownTrigger: true,
            }
        },
        mounted() {
            this.searchUsers();
        },
        methods: {
            searchUsers() {
                this.isLoading = true;

                this.users = [];
                this.totalPages = 0;
                this.totalResults = 0;
                this.errorMessage = null;

                var postBody = {
                    searchTerm: this.userSearch,
                    page: this.currentPage,
                    resultsPerPage: this.resultsPerPage,
                    accountPublicId: this.accountPublicId,
                    userRole: this.userRole ? this.userRole : 0,
                    userStatus: this.userStatus ? this.userStatus : 0,
                };

                securePost(this.hostUrl + "v1/user/search-users", postBody)
                    .then(data => {
                        this.isLoading = false;

                        if (data && data.success) {
                            this.users = data.users;
                            this.currentPage = data.currentPage;
                            this.totalPages = data.totalPages;
                            this.totalResults = data.totalResults;
                        }
                        else {
                            this.errorMessage = "Error Retrieving Users: " + data.message;
                        }
                    });
            },
            newSearch() {
                this.currentPage = 0;
                this.searchUsers();
            },
            clearSearchTerm() {
                this.userSearch = '';
                this.currentPage = 0;
                this.searchUsers();
                this.$refs.searchBox.focus();
            },
            setPage(page) {
                this.currentPage = page;
                this.searchUsers();
            },
            clearFilters() {
                this.userStatus = null;
                this.userRole = null;
                this.accountPublicId = null;
                this.newSearch();
            },
            accountSelected(publicId) {
              this.accountPublicId = publicId;
              this.newSearch();
            },
            userStatusSelected(status) {
                this.userStatus = status;
                this.newSearch();
            },
            userRoleSelected(role) {
                this.userRole = role;
                this.newSearch();
            },
            veryifyDeleteUser(user) {
                if (confirm("Really delete " + user.publicId + "?")) {
                    if (confirm("Really really delete " + user.publicId + "?")) {
                        secureDelete(this.hostUrl + "v1/user/" + user.publicId)
                            .then(data => {
                                this.isLoading = false;

                                if (data && data.success) {
                                    this.searchUsers();
                                }
                            });
                    }
                }
            },
            dropdownActiveChange() {
                this.$nextTick(() => {
                    this.usersDropdownActive = !this.usersDropdownActive;
                });
                this.showAddUserDropdownTrigger = !this.showAddUserDropdownTrigger;
            },
        }
    };
</script>

<style scoped>
    #search-button {
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
    }
    .total-results {
        float: right;
    }
</style>

