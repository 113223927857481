<template>
    <div class="modal-background">
        <div v-show="!isResultView">
            <div class="modal-card pt-6" id="import-modal">
                <header class="modal-card-head">
                    <p class="modal-card-title">Create Multiple Users</p>
                    <button class="delete is-medium" @click="close()" aria-label="close"></button>
                </header>
                <section class="modal-card-body" id="import-modal-body">
                    <div class="content">
                        <p> You can upload multiple users to an account using a CSV import. <a @click="downloadTemplate()">Download a template</a> to populate and see Field descriptions. Required columns are listed below. NOTE: Since this is a CSV file, do not use commas in the user's name. </p>
                        <p> Make your selections below for the users you are uploading. What is selected will apply to all of the users in your upload. </p>
                    </div>
                    <label class="label">Account Id</label>
                    <div class="field has-addons">
                        <div class="control is-expanded">
                            <input class="input" type="text" placeholder="Account id..." v-model="accountId">
                        </div>
                        <AccountDropdown @accountSelected="accountSelected"/>
                    </div>
                    <div class="field columns">
                        <div class="column is-one-third">
                            <label class="label">User Role</label>
                            <b-dropdown v-model="userRole" :disabled="isLoading">
                                <template #trigger>
                                    <div class="button" aria-haspopup="true" aria-controls="dropdown-menu">
                                        <span v-if="!userRole">Select</span>
                                        <span>{{ roleTypeDictionary[userRole] }}</span>
                                        <span class="icon is-small">
                                            <font-awesome-icon :icon="['fa', 'angle-down']" />
                                        </span>
                                    </div>
                                </template>
                                <b-dropdown-item 
                                    v-for="(index, userRoleKey) in roleTypeDictionaryFiltered" 
                                    :key="'userRole' + index"
                                    :value="{ userRoleKey }"
                                    aria-role="listitem"
                                    @click="setUserRole(userRoleKey)">
                                    {{ roleTypeDictionary[userRoleKey] }}
                                </b-dropdown-item>
                            </b-dropdown>
                        </div>
                        <div v-show="userRole == 1" class="column is-one-third">
                            <label class="label">Permission Template</label>
                            <PermissionTemplateDropdown @templateSelected="setPermissionTemplate" @interface="getPermissionTemplateInterface" />
                        </div>
                    </div>
                    <div class="label mt-5"> Required Fields </div>
                    <div class="content">
                        <ul class="list">
                            <li class="list-item">Email</li>
                            <li class="list-item">First Name</li>
                            <li class="list-item">Last Name</li>
                            <li class="list-item">Line Permission Columns <span class="is-size-7 is-italic mt-1">(only required if Standard role is selected)</span></li>
                        </ul>
                    </div>
                    <div class="file has-name is-primary is-fullwidth">
                        <label class="file-label">
                            <input class="file-input" type="file" name="userImportFile" @change="setValue($event)">
                            <span class="file-cta">
                                <span class="icon is-small">
                                    <font-awesome-icon :icon="['fa', 'upload']" />
                                </span>
                                <span class="file-label ml-3">
                                    Upload CSV
                                </span>
                            </span>
                            <span class="file-name">
                                {{ fileName || 'No file chosen...' }}
                            </span>
                        </label>
                    </div>
                    <div class="columns mt-2">
                        <div class="field column is-narrow">
                            <label class="switch-label">
                                <input type="checkbox" class="switch" v-model="sendInvites" />
                                Send Invitation Emails
                            </label>
                        </div>
                        <div class="field column px-0">
                            <p class="is-size-7 is-italic mt-1" id="help-text">(If unchecked, users will be setup with a default password)</p>
                        </div>
                    </div>
                </section>
                <footer class="modal-card-foot">
                    <button :class="isLoading ? 'button is-primary is-loading' : 'button is-primary'" :disabled="!isValid" @click="uploadUsers()">Upload Users</button>
                    <button class="button is-primary" @click="close()">Cancel</button>
                </footer>
            </div>
        </div>
        <div v-show="isResultView">
            <div class="modal-card pt-6" id="import-modal">
                <header class="modal-card-head">
                    <p class="modal-card-title">Results</p>
                    <button class="delete" @click="close()" aria-label="close"></button>
                </header>
                <section class="modal-card-body" id="import-modal-body">
                    <div v-if="usersSuccess && !permissionErrors" class="message is-success">
                        <div class="message-header">
                            Success
                        </div>
                        <div class="message-body">
                            <div class="content pb-2">
                                <p><strong>Users Successfully created: {{ this.usersCreated }}</strong></p>
                                <p><strong>Users Failed to create: {{ this.usersFailed }}</strong></p>
                                <p v-if="this.errorMessage">Service Message: {{ this.errorMessage }}</p>
                            </div>
                            <button class="button is-success" @click="downloadLogFile()">
                                <span class="icon">
                                    <font-awesome-icon :icon="['fa', 'download']" />
                                </span>
                                <span>Download Log File</span>
                            </button>
                        </div>
                    </div>
                    <div v-else-if="usersSuccess && permissionErrors" class="message is-warning">
                        <div class="message-header">
                            Success With Errors
                        </div>
                        <div class="message-body">
                            <div class="content pb-2">
                                <p><strong>Users Successfully created: {{ this.usersCreated }}</strong></p>
                                <p><strong>Users Failed to create: {{ this.usersFailed }}</strong></p>
                                <p>Warning: Completed with permission errors. Check log file.</p>
                                <p v-if="this.errorMessage">Service Message: {{ this.errorMessage }}</p>
                            </div>
                            <button class="button is-warning" @click="downloadLogFile()">
                                <span class="icon">
                                    <font-awesome-icon :icon="['fa', 'download']" />
                                </span>
                                <span>Download Log File</span>
                            </button>
                        </div>
                    </div>
                    <div v-else class="message is-danger">
                        <div class="message-header">
                            Import Failure
                        </div>
                        <div class="message-body">
                            <div class="content pb-2">
                                <p><strong>Users Successfully created: {{ this.usersCreated }}</strong></p>
                                <p><strong>Users Failed to create: {{ this.usersFailed }}</strong></p>
                                <p>{{ this.errorMessage }}</p>
                            </div>
                            <div v-if="this.logFile">
                                <button class="button is-danger" @click="downloadLogFile()">
                                    <span class="icon">
                                        <font-awesome-icon :icon="['fa', 'download']" />
                                    </span>
                                    <span>Download Log File</span>
                                </button>
                            </div>
                        </div>
                    </div>
                </section>
                <footer class="modal-card-foot">
                    <button class="button is-primary" @click="closeResults()">Upload More Users</button>
                    <button class="button is-primary" @click="close()">Close</button>
                </footer>
            </div>
        </div>
    </div>
</template>

<script>
    import { secureForm } from '../secureFetch.js';
    import AccountDropdown from './AccountDropdown';
    import PermissionTemplateDropdown from './PermissionTemplateDropdown';

    export default {
    name: "ImportUserModal",
    computed: {
        hostUrl: function () {
            return this.$store.state.hostUrl;
        },
        roleTypeDictionaryFiltered: function() {
            var rolesFiltered = JSON.parse(JSON.stringify(this.roleTypeDictionary));
            delete rolesFiltered[0];
            return rolesFiltered;
        },
        isValid: function() {
            return ((this.file != null && this.accountId != null && this.userRole != null) && (this.userRole == 2 ? true : this.permissionTemplateJson != null));
        },
        fileName: function() {
            if (this.file) {
                return this.file.name;
            }
            return null;
        }
    },
    data() {
        return {
            accountId: null,
            roleTypeDictionary: { 0: "Unknown", 1: 'Standard', 2: 'Admin', 3: 'Integration' },
            userRole: null,
            file: null,
            isLoading: false,
            isResultView: false,
            usersSuccess: false,
            usersCreated: 0,
            usersFailed: 0,
            permissionErrors: false,
            errorMessage: null,
            logFile: null,
            permissionTemplateJson: null,
            sendInvites: false,
        };
    },
    mounted() {
    },
    methods: {
        close() {
            this.accountId = null;
            this.userRole = null;
            this.file = null;
            this.isLoading = false;
            this.isResultView = false;
            this.usersSuccess = false;
            this.usersCreated = 0;
            this.usersFailed = 0;
            this.permissionErrors = false;
            this.errorMessage = null;
            this.logFile = null;
            this.sendInvites = false;
            this.clearSelectedTemplate();
            this.$emit("close");
        },
        closeResults() {
            this.isResultView = false;
            this.usersSuccess = false;
            this.usersCreated = 0;
            this.usersFailed = 0;
            this.permissionErrors = false;
            this.errorMessage = null;
            this.logFile = null;
        },
        accountSelected(accountId) {
            this.accountId = accountId;
        },
        setUserRole(role) {
            this.userRole = parseInt(role);
        },
        setValue(event) {
            this.file = event.target.files[0];
        },
        async uploadUsers() {
            this.isLoading = true;
            
            var formData = new FormData();
            formData.append("AccountId", this.accountId);
            formData.append("UserRole", this.userRole);
            formData.append("SendInvites", this.sendInvites);
            formData.append("ImportFile", this.file);
            if (this.userRole == 1) {
                formData.append("TemplateJson", this.permissionTemplateJson);
            }
            
            const result = await secureForm(this.hostUrl + "v1/user/create-users", formData);

            this.isLoading = false;

            if (result != null) {
                this.usersSuccess = result.success;
                this.permissionErrors = result.permissionErrors;
                this.usersCreated = result.usersCreated;
                this.usersFailed = result.usersFailed;
                this.errorMessage = result.message;
                this.logFile = result.file;

                this.isResultView = true;
            }
        },
        downloadLogFile() {
            var link = document.createElement("a");
            link.download = this.logFile.fileDownloadName;
            link.href = `data:text/csv;base64,${this.logFile.fileContents}`;
            link.click();
        },
        downloadTemplate() {
            const templateAsBase64 = 'RW1haWwsRmlyc3QgTmFtZSxMYXN0IE5hbWUsT3V0Ym91bmQgTGluZSAxLE91dGJvdW5kIExpbmUgMixPdXRib3VuZCBMaW5lIDMsT3V0Ym91bmQgTGluZSA0LE91dGJvdW5kIExpbmUgNSxPdXRib3VuZCBMaW5lIDYsT3V0Ym91bmQgTGluZSA3LE91dGJvdW5kIExpbmUgOCxPdXRib3VuZCBMaW5lIDksT3V0Ym91bmQgTGluZSAxMAo=';
            var link = document.createElement("a");
            link.download = 'user_bulk_creation_CRUDy_template.csv';
            link.href = `data:text/csv;base64,${templateAsBase64}`;
            link.click();
        },
        setPermissionTemplate(template) {
            this.permissionTemplateJson = template;
        },
        getPermissionTemplateInterface(childInterface) {
            this.$options.permissionTemplateInterface = childInterface;
        },
        clearSelectedTemplate() {
            this.permissionTemplateJson = null;
            this.$options.permissionTemplateInterface.clearSelectedTemplate();
        }
    },
    components: { AccountDropdown, PermissionTemplateDropdown }
};
</script>

<style scoped>
    #import-modal {
        overflow:visible;
        max-width: 820px;
        width: fit-content;
    }
    .modal-card-body {
        max-width: 780px;
        width: fit-content;
    }
    .list {
        padding: 0;
        margin-top: 0;
        margin-bottom: 0;
    }
    .list-item {
        margin: 0;
    }
    a {
        text-decoration: underline;
        color: hsl(207, 61%,  53%);
    }
</style>